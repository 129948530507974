import React, { useState, useEffect} from 'react';
import api from '../conexao.js';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState,  convertToRaw, ContentState } from 'draft-js';
import { Buffer } from 'buffer';
import iconv from 'iconv-lite';

// import cabecalho from '../../img/novo.jpg'
// import rodape from '../../img/rodape.jpg'

// import controladoRodape from '../../img/controladoRodape.jpg'
// import controladoCabecalho from '../../img/controladoCabecalho.jpg'

import {Modal, Form, Button} from 'react-bootstrap';
import logOut from '../logOut';
// import { formatDate } from 'react-calendar/dist/cjs/shared/dateFormatter.js';

export default function Consulta(props){

const [html, setHTML] = useState('');
const [contentBlock] = useState(htmlToDraft(html));
const [contentState] = useState(ContentState.createFromBlockArray(contentBlock.contentBlocks));
const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));   

const [textoBanco, setTextoBanco] = useState();
const [data, setData] = useState(new Date())

const [consulta, setConsulta] = useState([]);
const [codConsulta, setCodConsulta] = useState();
const [disableData, setDisableData] = useState(false);


const [edicao, setEdicao] = useState(false);

const [auxStatus, setAuxStatus] = useState(false)
const [btnCopiar, setBtnCopiar] = useState (true)

const [modalMSG, setModalMSG] = useState(false);
const [MSG, setMSG] = useState();
const [modalAnamneseAnt, setModalAnamneseAnt] = useState(false);
const [loading, setLoading] = useState(true)


function onEditorStateChange(editorState) {
    setEditorState(editorState)
    setTextoBanco(draftToHtml(convertToRaw(editorState.getCurrentContent())));
};

function transformaDataBR(d){
    var date = new Date(d)                      // converte a data ISO DateTimeStamp(como vem do banco) para DD/MM/YYYY
    date.setHours(0,0,0,0)                      // zera a hora 00:00:00
    return(date.getDate()+'/' + (date.getMonth()+1) + '/'+date.getFullYear())      
}



useEffect(() => {
        
    async function buscaConsulta() {
        await api.get('/consulta',{
        params: {           
            idPac: props.idPac
        }                
    }).then ( result => {
        
        result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
            COD1 = COD1.CODIGO;
            COD2 = COD2.CODIGO;

            if (COD1 > COD2) {
                return -1;
            }
            if (COD1 < COD2) {
                return 1;
            } return 0;
        })         

            result.data.map( r => {
                // console.log(r.DATA)
                r.DATA = transformaDataBR(r.DATA);                   
            })
            setConsulta(result.data);
            if (result.data[0]) {
                setCodConsulta(result.data[0].CODIGO);   
                // var oO = Buffer.from(result.data[0].TEXTO).toString();
                // if (oO.includes('�')) {
                //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].TEXTO).toString('latin1')))));    
                // } else
                // setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].TEXTO).toString())))); 
                setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data[0].TEXTOCONVERTIDO))));
                // setBtnExcluir(false)
            } else {
                // setBtnExcluir(true) 
            }
            
    }
    ).catch( (error) => {
        setMSG(logOut(error))
        setModalMSG(true)
    })
}

    
    buscaConsulta();
    setEdicao(true);
    setLoading(false);
    
},[props.idPac]);

async function handleNovaConsulta(){
           // aux para saber se é um novo receituario se TRUE é novo RECIETA
    data.setHours(0,0,0,0) 


    setEdicao(false) // destrava o botão GRAVAR

    setAuxStatus(true)
    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(''))));
    
    setBtnCopiar(false);

    setDisableData(true) //desativa o campo de data para não poder trocar
}

function transformaData(d){                     // converte data = new Date() para DD/MM/YYYY
    var date = new Date(d)                      // converte a data ISO DateTimeStamp(como vem do banco) para DD/MM/YYYY
    date.setHours(0,0,0,0)                      // zera a hora 00:00:00
    return(date.getFullYear()+'/' + (date.getMonth()+1) + '/'+date.getDate())   
}


async function handleGravar(codRec){       
        await api.post('/consulta', {
            COD_PACIENTE: parseInt(props.idPac),
            DATA: transformaData(data),
            TEXTO: textoBanco,  
            TIPO_CONSULTA : 1
  }).then ( teste => {

    setMSG('Gravado')
    setModalMSG(true)
    
    async function buscaConsulta() {
        await api.get('/consulta',{
        params: {           
        idPac: props.idPac
        }                
        }).then (result => {
            
            result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                COD1 = COD1.CODIGO;
                COD2 = COD2.CODIGO;
    
                if (COD1 > COD2) {
                    return -1;
                }
                if (COD1 < COD2) {
                    return 1;
                } return 0;
            }) 
            
            
            result.data.map( r => {
                r.DATA = transformaDataBR(r.DATA)    
            })

            setConsulta(result.data);
            setCodConsulta(result.data[0].CODIGO);            
            // setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(iconv.decode(Buffer.from(result.data[0].TEXTO),"ISO-8859-1")))));
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data[0].TEXTOCONVERTIDO))));
            
        }).catch( (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
        })
    }
            buscaConsulta();
            setDisableData(false) //Ativa o campo data para alterar, depois de gravado
            setBtnCopiar(true)
            setEdicao(true)
            


    })
    .catch( (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
        })
}

function handleCodConsulta(e) {
     setCodConsulta(e)
     setEdicao(true)
     consulta.map( consult => {
         if (parseInt(consult.CODIGO) === parseInt(e)) {
            // var oO = Buffer.from(consult.TEXTOCONVERTIDO).toString();
            // if (oO.includes('�')) {
            //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(consult.TEXTOCONVERTIDO).toString('latin1')))));    
            // } else
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(consult.TEXTOCONVERTIDO))));   
         } 
        })
}



function anamneseAnterior(e){
    if (e)  
    {let cons
    // ult = consulta.length - 1;
    cons = consulta;
    
    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft('<p>------------------------------------------------</p>'+ '</br>'  + cons[0].TEXTOCONVERTIDO))));   
    // setTextoBanco(cons[0].TEXTO)
    }
}


return(
    <div className="container-fluid">  
        <br />
        <div className=" form-row">
            <div className=" btn-group-vertical col-md-2">
                <Button variant="primary" size="sm"  onClick={() => handleNovaConsulta()}>Novo</Button> 
                <Button variant="info" size="sm" disabled={btnCopiar} onClick={() => anamneseAnterior(codConsulta)}>Copiar Anamnese Anterior</Button> 
                {/* <Button variant="info" size="sm" disabled={btnCopiar} onClick={() => setModalAnamneseAnt(true)}>Visualizar Anamneses Anteriores</Button>  */}
                    
            </div>
            <div className="col-md-1">
            </div>
            <br/>
            <div className="col-md-2">
                Atendimentos:
                                {/* CONDICIONAL PARA MOSTRAR DATA DO DIA QUANDO CLICAR EM NOVO */}
                {disableData && 
                    <Form.Control  size="sm" as="select" 
                        value={codConsulta} 
                        onChange={e => handleCodConsulta(e.target.value)} 
                        disabled={disableData}>           
                        
                        <option >{transformaDataBR(data)}</option> 
                    </Form.Control> }

                                                    {/* SE disableData = false */}

                {!disableData && 
                    <Form.Control  size="sm" as="select" 
                        value={codConsulta} 
                        onChange={e => handleCodConsulta(e.target.value)} 
                        disabled={disableData}>           
                        {  consulta.map( consult => (
                            <option value={consult.CODIGO}>{consult.DATA}</option> 
                            ))}
                    </Form.Control> }

                
            </div>
            
            <div className="col-md-1">
            </div>
        </div>
        <div >
            <Editor 
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={onEditorStateChange}
                    placeholder="Digite o Texto"
                />
        </div>
        <Button variant="primary" disabled={edicao} onClick={() => handleGravar(codConsulta)}>Gravar</Button>
        


    <Modal show={modalMSG}>
        <Modal.Header> 
            <h3>{MSG}</h3>
        </Modal.Header> 
        <Modal.Footer>
            <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
        </Modal.Footer>
    </Modal> 
    <Modal show={modalAnamneseAnt}>
        <Modal.Header> 
            <h3>Atendimentos</h3>
        </Modal.Header> 
        <Modal.Body>

        </Modal.Body>
        <Modal.Footer>
            <Button variant="info" onClick={() => setModalAnamneseAnt(false)}>Ok</Button>
        </Modal.Footer>
    </Modal> 
        <Modal show={loading}>
               <Modal.Body>
                Aguarde....
                </Modal.Body>
        </Modal>  
    </div>
)

}

