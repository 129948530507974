import React from 'react';


function Teste(){
    return(
        <div>
    <h1>Teste</h1>
    </div>
    )
}

export default Teste;