import axios from 'axios';


//const api = axios.create({baseURL:'https://api.solutionsti.info:443/api'});
//const api = axios.create({baseURL:'http://192.168.0.104:8080/api'});
// const api = axios.create({baseURL:'http://10.0.0.142:3030/api'});
const api = axios.create({baseURL:'https://apisti.ddns.net/api'})  //DigitalOcean
// const api = axios.create({baseURL:'http://localhost:3030/api'});


export default api;