import React from 'react';
import Menu from './componentes/menu';
import { Outlet,Route, Routes, BrowserRouter} from "react-router-dom";
import './App.css';


import DashBoard from './componentes/dashboard';
import Paciente from './componentes/paciente/paciente';
import CadPaciente from './componentes/paciente/cadpaciente';
import EditarPaciente from './componentes/paciente/editarpaciente';
import Teste from './componentes/teste';
import Medico from './componentes/medico/medico';
import EditarMedico from './componentes/medico/editarmedico';
import CadMedico from './componentes/medico/cadmedico';
import Medicamento from './componentes/medicamento/medicamento';
import EditarMedicamento from './componentes/medicamento/editarmedicamento';
import CadMedicamento from './componentes/medicamento/cadmedicamento';
import Texto from './componentes/texto/texto';
import EditarTexto from './componentes/texto/editartexto';
import CadTexto from './componentes/texto/cadtexto';
import Agenda from './componentes/agenda/agenda';
import Agendamento from './componentes/agenda/agendamento';
import AgendaEditar from './componentes/agenda/agendaeditar';
import FichaPaciente from './componentes/fichapaciente/fichapaciente';
import FichaPacienteAcesso from './componentes/fichapaciente/fichapacienteacesso';
import RelReceituario from './componentes/fichapaciente/relreceituario';
import ImpAgenda from './componentes/relatorios/impagenda';
import ConfAgenda from './componentes/agenda/confagenda';

import Login from '../src/login';
import Clinica from './componentes/clinica/clinica';







// const rotas = Routes([
  
//   {path: "/", 
//   element: <DashBoard />,
//   children: [
//     {path: "/",element: <Paciente />},
//     {path: "/login", element: <Login />},
//     {path: "/paciente/paciente", element: <Paciente />}
// ]}
  // {path: "/paciente/cadpaciente", element: <CadPaciente />},
  // {path: "/teste", element: <Teste />},
  // {path: "/paciente/editarpaciente/:id'", element: <EditarPaciente id={id}/>},
  // {path: "/medico/medico", element: <Medico/>},
  // {path: "/medico/editarmedico/:id", element: <EditarMedico id={id} />},
  // {path: "/medico/cadmedico", element: <CadMedico />},

  // {path: "/agenda/agenda", element: <Agenda />},
  // {path: "/agenda/agendamento/:idMed/:date/:horaProps", element: <Agendamento idMed={idMed} date={date} horaProps={horaProps} />},
  // {path: "/agenda/agendaeditar/:id/:idPac", element: <AgendaEditar id={id} idPac={idPac} />},

  // {path: "/fichapaciente/fichapaciente/:idMed/:idPac", element: <FichaPaciente idMed={idMed} idPac={idPac} />},
  // {path: "/fichapaciente/fichapacienteacesso", element: <FichaPacienteAcesso />},
  // {path: "/fichapaciente/relreceituario", element: <RelReceituario />},

  // {path: "/medicamento/medicamento", element: <Medicamento />},
  // {path: "/medicamento/editarmedicamento/:id", element: <EditarMedicamento id={id} />},
  // {path: "/medicamento/cadmedicamento", element: <CadMedicamento />},

  // {path: "/texto/texto", element: <Texto />},
  // {path: "/texto/editartexto/:id", element: <EditarTexto id={id} />},
  // {path: "/texto/cadtexto", element: <CadTexto />},

  // {path: "/relatorios/impagenda", element: <ImpAgenda />},
  // {path: "/agenda/confagenda", element: <ConfAgenda />},
// ])


function App() {  

  const AppLayout = () => {
    return(
   <>
       <Menu />   {/* // MEU MENU */}       
      <Outlet />  {/* // INDICA QUE AS PÁGINAS OU ROTAS VAO ABRIR ABAIXO DO MENU */}
      
    </>
    )
  }
                                        //OS PARAMETROS DAS URLS SÃO PASSADOS NO PATH E PEGADOS NO DESTINO COM  useParams
  const Rotas = () =>(
    <BrowserRouter>                                   {/* // PARA INDICAR QUE É ROTA */}    
      <Routes>                                        {/* // PARA INDICAR QUE É ROTA */}        
        <Route element={<AppLayout />}>               {/* // AQUI ELE CHAMA O APPLAYOUT ACIMA  */}        
          <Route path="/" element= {<DashBoard />}/>
          <Route path= "/login" element= {<Login />}/>

          <Route path="/paciente/paciente" element= {<Paciente />}/>
          <Route path="/paciente/cadpaciente" element= {<CadPaciente />}/>
          <Route path="/paciente/editarpaciente/:id" element= {<EditarPaciente />}/>

          <Route path="/clinica/clinica" element= {<Clinica />}/>

          <Route path="/teste" element= {<Teste />}/>

          <Route path="/medico/medico" element= {<Medico />}/>
          <Route path="/medico/editarmedico/:id" element= {<EditarMedico  />}/>
          <Route path="/medico/cadmedico" element= {<CadMedico />}/>

          <Route path="/agenda/agenda" element= {<Agenda />}/>
          <Route path="/agenda/agendamento/:idMed/:date/:horaProps/:encaixe" element= {<Agendamento />}/>
          <Route path="/agenda/agendaeditar/:id/:idPac/:encaixe" element= {<AgendaEditar   />}/>

          <Route path="/fichapaciente/fichapaciente/:idMed/:idPac" element= {<FichaPaciente  />}/>
          <Route path="/fichapaciente/fichapacienteacesso" element= {<FichaPacienteAcesso />}/>
          <Route path="/fichapaciente/relreceituario" element= {<RelReceituario />}/>

          <Route path="/medicamento/medicamento" element= {<Medicamento />}/>
          <Route path="/medicamento/editarmedicamento/:id" element= {<EditarMedicamento  />}/>
          <Route path="/medicamento/cadmedicamento" element= {<CadMedicamento />}/>

          <Route path="/texto/texto" element= {<Texto />}/>
          <Route path="/texto/editartexto/:id" element= {<EditarTexto  />}/>
          <Route path="/texto/cadtexto" element= {<CadTexto />}/>

          <Route path="/relatorios/impagenda" element= {<ImpAgenda />}/>
          <Route path="/agenda/confagenda" element= {<ConfAgenda />}/>
        </Route>
      </Routes>
    </BrowserRouter> 
    )


    return (
      <>
         
        <Rotas />  {/* CHAMA AS ROTAS ACIMA */}
        

      </>
      )
  }   

export default App; 
