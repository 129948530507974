import React, { useState, useEffect} from 'react';
import api from '../conexao.js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState,  convertToRaw, ContentState } from 'draft-js';
import {Modal, Form, Button, FormSelect} from 'react-bootstrap';
import logOut from '../logOut';
import PrintReports from './print.js';

export default function Exame(props){

    const [html, setHTML] = useState('');
    const [contentBlock] = useState(htmlToDraft(html));
    const [contentState] = useState(ContentState.createFromBlockArray(contentBlock.contentBlocks));
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));   
    
    const [textoBanco, setTextoBanco] = useState();
    const [data, setData] = useState(new Date())
    const [dataTexto, setDataTexto] = useState()
    
    const [exame, setExame] = useState([]);
    const [codExame, setCodExame] = useState();
    const [texto, setTexto] = useState([]);
    const [codTexto, setCodTexto] = useState();
    const [disableData, setDisableData] = useState(false);
    const [mostraModal, setMostraModal] = useState(false);
    const [modalGravado, setModalGravado] = useState(false);
    const [MSG, setMSG] = useState();
    const [modalMSG, setModalMSG] = useState(false);
    
    const [auxStatus, setAuxStatus] = useState(false)
    const [btnExcluir, setBtnExcluir] = useState(false)  
    const [dataBR, setDataBR] = useState();
    const [loading, setLoading] = useState(true)

    function onEditorStateChange(editorState) {
        setEditorState(editorState)
        setTextoBanco(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };
    
    function transformaData(d){
        var date = new Date(d)                      // converte a data ISO DateTimeStamp(como vem do banco) para DD/MM/YYYY
        date.setHours(0,0,0,0)                      // zera a hora 00:00:00
        return(date.getDate()+'/' + (date.getMonth()+1) + '/'+date.getFullYear())      
    }

    function transformaDataBanco(d){                     // converte data = new Date() para DD/MM/YYYY
        var date = new Date(d)                      // converte a data ISO DateTimeStamp(como vem do banco) para DD/MM/YYYY
        date.setHours(0,0,0,0)                      // zera a hora 00:00:00
        return(date.getFullYear()+'/' + (date.getMonth()+1) + '/'+date.getDate())   
    }
    
    useEffect(() => {
            
        async function buscaExame() {
          const x =  await api.get('/exame',{
            params: {           
                idPac: props.idPac
            }                
        }).then ( result => {

            result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                COD1 = COD1.CODIGO;
                COD2 = COD2.CODIGO;
    
                if (COD1 > COD2) {
                    return -1;
                }
                if (COD1 < COD2) {
                    return 1;
                } return 0;
            }) 

                result.data.map( (r) => {
                    r.DATA = transformaData(r.DATA)    
                })
                setExame(result.data);
                
                if (result.data[0]) {  
                    
                    setCodExame(result.data[0].CODIGO);           
                    // var oO = Buffer.from(result.data[0].EXAME_TEXTO).toString();
                    
                    // if (oO.includes('�')) {
                    //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].EXAME_TEXTO).toString('latin1')))));    
                    // } else
                    // setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].EXAME_TEXTO).toString()))));          
         
                    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data[0].TEXTOCONVERTIDO))));
                    setBtnExcluir(false) 
                    setDataBR(result.data[0].DATA)
                    
                } else {
                    setBtnExcluir(true) 
                    
                }
    
        }).catch(
            (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
            }
         )
    }
    
        async function buscaTexto(){
            const x =    await api.get('/textoportipo',{
                params: {           
                    tipoTexto: 9
                }                
            }).then ( result => {
             
                        setTexto(result.data)
                        if (result.data[0]) {
                            setCodTexto(result.data[0].CODIGO) 
                            }  // para registrar o codTexto, pois select nao define codTexto 
                                                            //ao listar quando carrega tela.
            }).catch(
                (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
                }
             )
        }
        
        buscaExame();
        buscaTexto();
        setLoading(false)
    
    },[props.idPac]);
    
     async function handleNovoExame(){
               
        data.setHours(0,0,0,0) 
        
        setDisableData(true) //desativa o campo de data para não poder trocar
    
        await api.post('/exame', {
                COD_PACIENTE: parseInt(props.idPac),
                DATA: transformaDataBanco(data),
                EXAME_TEXTO: '<p></p>'
       }).catch(
        (error) => {
        setMSG(logOut(error))
        setModalMSG(true)
        }
     )
    
        await api.get('/exame',{
                params: {           
                    idPac: props.idPac
                }                
        }).then ( result => {

            result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                COD1 = COD1.CODIGO;
                COD2 = COD2.CODIGO;
    
                if (COD1 > COD2) {
                    return -1;
                }
                if (COD1 < COD2) {
                    return 1;
                } return 0;
            }) 

                        result.data.map( r => {
                        r.DATA = transformaData(r.DATA)    
        })
        setExame(result.data);
        setCodExame(result.data[0].CODIGO);
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(''))));
        setDataTexto(transformaData(data))
        setBtnExcluir(false)
    }).catch(
        (error) => {
        setMSG(logOut(error))
        setModalMSG(true)
        }
     )
    setAuxStatus(true)
    }
    
async function handleGravar(codRec){
    
            data.setHours(0,0,0,0) 
            await api.put('/exame/'+ codRec, {
                COD_PACIENTE: parseInt(props.idPac),
                DATA: transformaDataBanco(data),
                EXAME_TEXTO: textoBanco
            }).then(
                // setBtnExcluir(true),
                setMSG('Gravado!'),
                setModalMSG(true),
                
                )
            .catch(
                (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
                }
             )
    
    
        async function buscaExame() {
            await api.get('/exame',{
                params: {           
                    idPac: props.idPac
                }                
        }).then ( result => {

            result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                COD1 = COD1.CODIGO;
                COD2 = COD2.CODIGO;
    
                if (COD1 > COD2) {
                    return -1;
                }
                if (COD1 < COD2) {
                    return 1;
                } return 0;
            }) 

                        result.data.map( r => {
                        r.DATA = transformaData(r.DATA)    
        })
        setExame(result.data);
        setDataBR(result.data[0].DATA)
        setCodExame(result.data[0].CODIGO);
        // var oO = Buffer.from(result.data[0].EXAME_TEXTO).toString();
        // if (oO.includes('�')) {
        //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].EXAME_TEXTO).toString('latin1')))));    
        // } else
        // setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].EXAME_TEXTO).toString()))));          
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data[0].TEXTOCONVERTIDO))));
    
        }).catch(
            (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
            }
         )
    }
        buscaExame();
        setDisableData(false) //Ativa o campo data para alterar, depois de gravado
}
    
    function handleCodExame(e) {
         setCodExame(e)
         exame.map( exam => {
             if (parseInt(exam.CODIGO) === parseInt(e)) {
                setDataTexto(exam.DATA)
                // var oO = Buffer.from(exam.EXAME_TEXTO).toString();
                // if (oO.includes('�')) {
                //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(exam.EXAME_TEXTO).toString('latin1')))));    
                // } else
                // setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(exam.EXAME_TEXTO).toString()))));          
                setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(exam.TEXTOCONVERTIDO))));   
                setDataBR(exam.DATA) 
             }
            })
    }
    
    function handleAdicionaTexto(){
        var textoAlterado;
        if ( typeof codTexto === 'undefined' ) {
            // alert('Selecione o Texto')
            setMSG('Selecione o Texto');
            setModalMSG(true)
          } else {
        texto.map( text => {
            if (parseInt(text.CODIGO) === parseInt(codTexto)) {

                // if (Buffer.from(text.TEXTO).toString().includes('�')) {   
                //     textoAlterado = Buffer.from(text.TEXTO).toString('latin1')
                // } else {
                //     textoAlterado = Buffer.from(text.TEXTO).toString()           
                // }
                
                textoAlterado = text.TEXTOCONVERTIDO;
                textoAlterado = textoAlterado.replace("%data", dataTexto)
                textoAlterado = textoAlterado.replace("%nome", props.paciente)
                onEditorStateChange(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(
                    draftToHtml(convertToRaw(editorState.getCurrentContent()))  + textoAlterado ))));                         // VAI SER USADO PARA ADD TEXTO
            } 
        })
    }
    
    }
     
    function handleMostraModal(m){
         setMostraModal(m)
    }
    
    async function handleExcluirExame(cod){
        await api.delete('/exame/'+ parseInt(cod))
        .then(  
                setMSG('Excluido!'),
                setModalMSG(true),
                 setMostraModal(false)
                
                )
        .catch(
            (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
            }
         );
         
    
         
        await api.get('/exame',{
            params: {           
                idPac: props.idPac
            }                
            }).then ( result => {
                if (result.data[0]) {
                result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                    COD1 = COD1.CODIGO;
                    COD2 = COD2.CODIGO;
        
                    if (COD1 > COD2) {
                        return -1;
                    }
                    if (COD1 < COD2) {
                        return 1;
                    } return 0;
                }) 

                    result.data.map( r => {
                    r.DATA = transformaData(r.DATA)    
            })
            setExame(result.data);
            setDataBR(result.data[0].DATA)
            setCodExame(result.data[0].CODIGO);            
            // var oO = Buffer.from(result.data[0].EXAME_TEXTO).toString();
            // if (oO.includes('�')) {
            //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].EXAME_TEXTO).toString('latin1')))));    
            // } else
            // setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].EXAME_TEXTO).toString()))));          
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data[0].TEXTOCONVERTIDO))));
        
        }else{
            setBtnExcluir(true)
            setExame(result.data)
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(''))));
        }
            }).catch(
                (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
                }
             );
    
    } 

    function chamaPDF(){
        let auxTexto = convertToRaw(editorState.getCurrentContent()).blocks
        let texto =  auxTexto.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
        
        async function enderec() {
            await api.get('/medico/', {params: {
                codigo:parseInt(props.idMed)
            }} )
                .then (() => {PrintReports(texto, props.nomeMed, props.paciente, props.titulo, dataBR,props.crmMed,props.cabecalho,props.rodape)})
                .catch( (error) => {
                    setMSG(logOut(error))
                    setModalMSG(true)
                })              
        }
        enderec();
    
       // PrintReports(texto, props.nomeMed, props.paciente, props.titulo, dataBR,props.crmMed,props.cabecalho,props.rodape)
    }

    function setaCodTexto(x){
        setCodTexto(x)
    }


    return(
        <div className="container-fluid">  
            <br />
            <div className=" form-row">
                <div className=" btn-group-vertical col-md-1">
                    <Button variant="primary" size="sm" 
                        onClick={() => handleNovoExame()}
                        >Novo</Button>                  
                    <Button variant="danger" size="sm" 
                        onClick={() => handleMostraModal(true)}
                        disabled={btnExcluir}
                        >Excluir</Button>            
                    <Button variant="info" size="sm"
                        onClick={() => chamaPDF()}
                        >Imprimir</Button>
                        
                </div>
                <div className="col-md-1">
                </div>
                <div className="col-md-2">
                    Emitidos:

                    {!disableData && <Form.Select  size="sm" as="select" 
                        value={codExame} 
                        onChange={e => handleCodExame(e.target.value)} 
                        disabled={disableData} >                               
                        {exame.map( exam => (
                            <option value={exam.CODIGO}>{exam.DATA}</option>
                            ))}
                    </Form.Select>} 
                    
                    { disableData && <Form.Select  size="sm" as="select" 
                        value={codExame} 
                        onChange={e => handleCodExame(e.target.value)} 
                        disabled={disableData} >                               
                       
                            <option >{transformaData(data)}</option>
                           
                    </Form.Select> }
                </div>
                <div className="col-md-1">
                </div>
                <div className="col-md-3">
                    Texto:
                    <Form.Select  size="sm" as="select" 
                    value={codTexto} 
                    onChange={e => setCodTexto(e.target.value)} >                               
                                        {texto.map( text => (
                                            <option value={text.CODIGO}>{text.NOME}</option>
                                            ))}
                    </Form.Select> 
                    <Button variant="success" size="sm" onClick={m => handleAdicionaTexto()} >Adicionar Texto</Button>
                </div>
            </div>
            <div>
                <Editor 
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={onEditorStateChange}
                        placeholder="Digite o Texto"
                    />
            </div>
            <Button variant="primary" onClick={() => handleGravar(codExame)}>Gravar</Button>
    
            <Modal show={mostraModal}>
                <Modal.Header> 
                    <h3>Excluir Exame?</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="danger" onClick={() => handleExcluirExame(codExame)}>Excluir</Button>
                    <Button variant="info" onClick={() => handleMostraModal(false)}>Cancelar</Button>
                </Modal.Footer>    
            </Modal>
            <Modal show={modalGravado}>
                <Modal.Header> 
                    <h3>Gravado!</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalGravado(false)}>Ok</Button>
                </Modal.Footer>
            </Modal>    
            <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal>   
            <Modal show={loading}>
               <Modal.Body>
                Aguarde....
                </Modal.Body>
        </Modal>         
        </div>
    )

}