import React, { useState, useEffect }from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button, Form } from 'react-bootstrap';
import api from '../conexao.js';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, useParams } from 'react-router-dom';
import InputMask from 'react-input-mask';
import '../../App.css';
import logOut from '../logOut';
import AWS from 'aws-sdk';



export default function EditarMedico(){

        const nave = useNavigate();
        const {id} = useParams();
// EditarMedico.propTypes = {
//     id: PropTypes.number.isRequired
// }

const {register, handleSubmit, setValue} = useForm( );  //REGISTRANDO AS FUNÇÕES DO USEFORM
const [exibirModal, setExibirModal] = useState(false); // const para definir se a modal aparece ou nao
const [medNome, setMedNome] = useState();
const [tel, setTEL] = useState();
const [cel, setCEL] = useState();
const [cpf, setCPF] = useState();
const [modalMSG, setModalMSG] = useState(false);
const [MSG, setMSG] = useState();
const [cabecalho, setCabecalho] = useState();
const [rodape, setRodape] = useState();

var telnum = String;
var celnum = String;
var cpfnum = String;

useEffect( () => {
    api.get('/medico/',{  params: {           
        codigo: id,}}).then(result => {
           setValue("NOME", result.data.NOME);
           setValue("ENDERECO", result.data.ENDERECO);
           setValue("BAIRRO", result.data.BAIRRO);
           setValue("CIDADE", result.data.CIDADE);
           setValue("CRM", result.data.CRM);
           setCabecalho(result.data.CABECALHO);
           setRodape(result.data.RODAPE);

           if (result.data.TEL) {  
             setTEL(result.data.TEL.toString());
             setValue("TEL", tel);
           }
           if (result.data.CEL) { 
             setCEL(result.data.CEL.toString());
             setValue("CEL", cel);
           }
           if (result.data.CPF) { 
             setCPF(result.data.CPF.toString());
             setValue("CPF", cpf);     
           }        

    }) .catch(        
                (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
    })   
}, [id]);



function handleFecharModal(){
        setExibirModal(false); // inicia faalse
        nave('/medico/medico'); // vai para pagina anterior
}
function handleVoltar(){
        nave('/medico/medico');
}

 const onSubmit = (data) => {

        if  (tel)  {
                telnum = tel;
                telnum = telnum.replace(/[^0-9]+/g, "");
        }
        if (cel) {
                celnum = cel;
                celnum = celnum.replace(/[^0-9]+/g, "");
        }
        if (cpf) {
                cpfnum = cpf;
                cpfnum = cpfnum.replace(/[^0-9]+/g, "");
        }
        if (cabecalho) { data.CABECALHO = 'cab ' + data.NOME.substring(0,10).toLowerCase() + '.jpg'}
        if (rodape)    { data.RODAPE    = 'rod ' + data.NOME.substring(0,10).toLowerCase() + '.jpg'}

async        function Grava() {
              await  api.put('/medico/'+ id, {
                        NOME:  data.NOME.toUpperCase(),
                        ENDERECO: data.ENDERECO.toUpperCase(),
                        BAIRRO: data.BAIRRO.toUpperCase(),
                        CIDADE: data.CIDADE.toUpperCase(),
                        CRM: data.CRM.toUpperCase(),
                        TEL: telnum,
                        CEL: celnum,
                        CPF: cpfnum,
                        CABECALHO: data.CABECALHO,
                        RODAPE: data.RODAPE
                }).then(
                        () => {
                        setMedNome(data.NOME);
                        setExibirModal(true);
                        }
                ).catch(        
                        (error) => {
                        setMSG(logOut(error))
                        setModalMSG(true)
                })   
        }    

 

        const uploadCabecalho = async () => {
                const S3_BUCKET = "imgmedsolutions";
                const REGION = "sa-east-1";
                AWS.config.update({
                  accessKeyId: "AKIAY3BB4HQM7BZWG7OA",
                  secretAccessKey: "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",
                });
                const s3 = new AWS.S3({
                  params: { Bucket: S3_BUCKET },
                  region: REGION,
                });
            
                const params = {
                  Bucket: S3_BUCKET,
                  Key: data.CABECALHO,
                  Body: cabecalho,
                  Metadata: {
                        ContentType: 'image/jpeg',
                      }
                };
            
                var upload = s3
                  .putObject(params)
                  .on("httpUploadProgress", (evt) => {
                //     console.log(
                //       "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                //     );
                  })
                  .promise();
            
                // await upload.then((err, data) => {
                //   console.log(err);
                //   alert("File uploaded successfully.");
                // });
                await upload.catch((err, data) => {
                        if (err) {console.log(err)}
                        alert("File uploaded Failed.");
                });
        };

        const uploadRodape = async () => {
                const S3_BUCKET = "imgmedsolutions";
                const REGION = "sa-east-1";
                AWS.config.update({
                        accessKeyId: "AKIAY3BB4HQM7BZWG7OA",
                        secretAccessKey: "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",
                });
                const s3 = new AWS.S3({
                        params: { Bucket: S3_BUCKET },
                        region: REGION,
                });

                const params = {
                        Bucket: S3_BUCKET,
                        // Key: rodape.name,
                        Key: data.RODAPE,
                        Body: rodape,
                        Metadata: {
                          ContentType: 'image/jpeg',
                      }
                        
                };

                var upload = s3
                        .putObject(params)
                        .on("httpUploadProgress", (evt) => {
                        // console.log(
                        // "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                        // );
                        })
                        .promise();

                // await upload.then((err, data) => {
                //         console.log(err);
                //         alert("File uploaded successfully.");
                // });
                await upload.catch((err, data) => {
                        if (err) {console.log(err)}
                        alert("File uploaded Failed.");
                });
        };

        Grava();
        
       if (cabecalho) {uploadCabecalho()}
        if (rodape) {uploadRodape()} 
      
    }


    function funcCabecalho(e) {
        const cabeca = e.target.files[0];
        setCabecalho(cabeca);
    }

    function funcRodape(e) {
        const roda = e.target.files[0];
        setRodape(roda);
    } 

        return (

            
            <div className="w-90 p-3 mx-auto">
            <div className='Jumbotron'>
                <center> <h1> Editar Médico </h1> </center>
            <br />
            <div>
        <Form onSubmit={handleSubmit(onSubmit)}  >
          <div className="w-90 p-3 mx-auto">
            <div className="form-row">
                <div className="form-group col-md-6">
                        Nome:
                        <Form.Control Style="text-transform:uppercase;" 
                        size="sm" 
                        type="text"  
                        maxLength={45}
                        name="NOME"  
                        {...register('NOME')}
                        />
                </div>
                
                <div className="form-group col-md-2">
                CRM:
                    <Form.Control Style="text-transform:uppercase;" 
                    type="text" 
                    size="sm" 
                    maxLength={15}
                    name="CRM" 
                    {...register('CRM')} 
                    />
                </div>
        
            </div>
            <div className="form-row">
            <div className="form-group col-md-6">
                        Endereço:
                        <Form.Control Style="text-transform:uppercase;" maxLength={45} size="sm" type="text" name="ENDERECO"  {...register('ENDERECO')}/>
                </div> 
                <div className="form-group col-md-6">
                        Bairro:
                        <Form.Control Style="text-transform:uppercase;" maxLength={45} size="sm" type="text" name="BAIRRO" {...register('BAIRRO')}/>
                </div>   
            </div>

            <div className="form-row">
            <div className="form-group col-md-6">
                        Cidade:
                        <Form.Control Style="text-transform:uppercase;" maxLength={45} size="sm" type="text" name="CIDADE" {...register('CIDADE')}/>
                </div> 
 
            </div>
            <div className="form-row">
            <div className="form-group col-md-2">
                        Tel.:
                        <br/>
                        <InputMask
                                mask="(99)9999-9999"
                                size="24"
                                name="TEL"
                                {...register('TEL')}
                                value={tel}
                                onChange={(e) => setTEL(e.target.value)}
                                alwaysShowMask={false}
                        />

                </div> 
                <div className="form-group col-md-2">
                        Cel.:
                        <br/>
                        <InputMask
                                mask="(99)99999-9999"
                                size="24"
                                name="CEL"
                                {...register('CEL')}
                                value={cel}
                                onChange={(e) => setCEL(e.target.value)}
                                alwaysShowMask={false}
                        />
                </div>
                <div className="form-group col-md-2">
                        CPF:
                        <br />
                        <InputMask
                                mask="999.999.999-99"
                                size="24"
                                name="CPF"
                                {...register('CPF')}
                                value={cpf}
                                onChange={(e) => setCPF(e.target.value)}
                                alwaysShowMask={false}
                        />
                </div>  
                <br/>
                <div className="form-group col-md-4">
                Cabeçalho
                <br/>
                {cabecalho && <label Style="color: red;" > Já existe Cabeçalho associado!</label>}
                    <Form.Control  
                    type="file" name="cabecalho" accept="image/jpeg" Style="width:143px;"
                    onChange={e => funcCabecalho(e)} 
                    />{cabecalho && <Button className="btn btn-danger" onClick={() => {setCabecalho()}}>Remover</Button>}
                </div> 
                <br/>
                <div className="form-group col-md-4">
                Roda-pé
                <br/>
                {rodape && <label Style="color: red;" > Já existe Roda-pé associado!</label>}
                    <Form.Control
                    type="file" name="rodape" accept="image/jpeg" Style="width:143px;"
                    onChange={e => funcRodape(e)}
                    />{rodape && <Button className="btn btn-danger" onClick={() => {setRodape()}}>Remover</Button>}
                </div>
            </div>
            <br></br>
            <div className="form-row" >
                <div className="form-group col-md-10">
                    <Button 
                    className="btn btn-primary" 
                    type="submit" 
                    >
                        Gravar</Button>
                {/* </div>
                <div className="form-group col-md-2"> */}
                    <Button type="button" 
                    className="btn btn-secondary" 
                    onClick={() => {handleVoltar()}}
                    >
                Cancelar</Button>
                </div>    
            </div>   
          </div>
        </Form> 
        </div> 
            </div> 
        <Modal show={exibirModal} onHide={handleFecharModal} backdrop="static">
                <Modal.Header>
                        <h2> Alteração feita com Sucesso! </h2>
                </Modal.Header>
                <Modal.Body>
                  Médico: <strong>{medNome}</strong>, Alterado!                  
                </Modal.Body>
                <Modal.Footer>
                        <Button variante="success" onClick={() => handleFecharModal()}> Ok </Button>
                </Modal.Footer>
                
        </Modal>
        <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 
        </div>
 

                )

}



