import React, {useEffect, useState} from 'react';
import { Navbar, Nav, NavDropdown,Button } from 'react-bootstrap';
// import {A} from 'hookrouter';
import {Link} from 'react-router-dom'
import api from './conexao';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faBookMedical,faRightFromBracket} from "@fortawesome/free-solid-svg-icons"



function Menu () {
    

    let tokin = localStorage.getItem('token')  //LE O TOKEN QUE FOI GRAVADO NO LOGIN
    // let banco = localStorage.getItem('banco')  // le o banco no tokem

    

    var payload = tokin.split('.')[1];
    var decodedPayload = JSON.parse(window.atob(payload));
    // let userName = localStorage.getItem('param2')
    //  console.log(decodedPayload.param2)

    useEffect( () => {   

        // localStorage.removeItem('banco')
        // localStorage.removeItem('username')

        api.interceptors.request.use(
            config => {
                config.headers.authorization = `Bearer ${tokin}`;
                return config; 
            },
            error => {
                return Promise.reject(error);
            }
        )

    },[]);  

    function handleLougout(){
        window.location = '/'
        
    }


    return (
    <div>
       <div className="row"> 
       <div className="col-md-12">  
       
        <Navbar bg="light">
            <Navbar.Brand href="#home">
                <Link to="/">  
                <div>
                    <Button 
                        style={{marginLeft:'15px'}}
                        type="button" 
                        data-toggle="tooltip" data-placement="top" title="Página Principal">
                        <FontAwesomeIcon icon={faBookMedical} size='lg' />
                    </Button>
                </div> 
                    
                </Link>
        </Navbar.Brand>

            <Nav className="navbar navbar-expand-lg navbar-light bg-light" style={{ backgroundcolor: "#e3f2fd" }}>

                <NavDropdown title="Cadastro" id="collasible-nav-dropdown" >
                    <NavDropdown.Item ><Link to="/paciente/paciente" style={{ textDecoration: 'none', color: 'Black' }}>Paciente</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/medico/medico" style={{ textDecoration: 'none', color: 'Black' }}>Médico</Link></NavDropdown.Item>
                    <NavDropdown.Item ><Link to="/medicamento/medicamento" style={{ textDecoration: 'none', color: 'Black' }}>Medicamento</Link></NavDropdown.Item>
                    <NavDropdown.Item ><Link to="/texto/texto" style={{ textDecoration: 'none', color: 'Black' }}>Textos</Link></NavDropdown.Item>
                    {/* <NavDropdown.Item ><Link to="/clinica/clinica" style={{ textDecoration: 'none', color: 'Black' }}>Clínica</Link></NavDropdown.Item> */}
                    {/* <NavDropdown.Divider />
                    <NavDropdown.Item>Sair</NavDropdown.Item> */}
                </NavDropdown>
                <NavDropdown title="Movimento" id="collasible-nav-dropdown" >
                    <NavDropdown.Item><Link to="/agenda/agenda" style={{ textDecoration: 'none', color: 'Black' }}>Agenda</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/fichapaciente/fichapacienteacesso" style={{ textDecoration: 'none', color: 'Black' }}>Ficha Paciente</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/agenda/confagenda" style={{ textDecoration: 'none', color: 'Black' }}>Configuração da Agenda</Link></NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Relatórios" id="collasible-nav-dropdown" >
                <NavDropdown.Item ><Link to="/relatorios/impagenda" style={{ textDecoration: 'none', color: 'Black' }}>Imprimir Agenda</Link></NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Utilitários" id="collasible-nav-dropdown" >
                    <NavDropdown.Item>BackUp</NavDropdown.Item>
                </NavDropdown>
                {/* <NavDropdown title="Sair" id="collasible-nav-dropdown" >
                    <NavDropdown.Item><A href="/login" style={{ textDecoration: 'none', color: 'Black' }}>Sair</A></NavDropdown.Item>
                </NavDropdown> */}
            </Nav>
            <div  class="d-flex">
                <tr > 
                    <td ><span style={{fontWeight: 'bold'}}>Usuario: </span>    {decodedPayload.param2}</td>
                    <td>        
                        <div className="col-md-1" style={{backgroundColor: '#f8f9fa'}}>  
                            <Button 
                            // style={{marginTop: '15px'}} 
                            onClick={() => handleLougout()}>
                            <FontAwesomeIcon icon={faRightFromBracket} size='lg' ></FontAwesomeIcon>
                            </Button>
                        </div>
                    </td>
                </tr>
            </div>
            
        
        </Navbar>
        
       
     </div>
     
    </div>
        
        
 
    </div>

    
    )
}

export default Menu; //retornar funcao sempre sem ()