import React, { useState }from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button, Form, Jumbotron } from 'react-bootstrap';
import api from '../conexao.js';
import '../../App.css';
import { useNavigate } from 'react-router-dom';
import logOut from '../logOut.js';
// import logOut from '../logOut';

export default function CadMedicamento(){

    const nave= useNavigate();

const {register, handleSubmit} = useForm( );  //REGISTRANDO AS FUNÇÕES DO USEFORM
const [exibirModal, setExibirModal] = useState(false); // const para definir se a modal aparece ou nao
const [modalMSG, setModalMSG] = useState(false);
const [MSG, setMSG] = useState();

function handleFecharModal(){
        setExibirModal(false); // inicia faalse
        nave('/medicamento/medicamento'); // vai para pagina anterior
        // window.location.reload(); //da um load na pagina
}

function handleVoltar(){
        nave('/medicamento/medicamento');
        // window.location.reload(); 
}

 const onSubmit = async (data) => {

        const response =
            await api.post('/medicamento', {
                NOME: data.NOME.toUpperCase(),
                DOSE: data.DOSE.toUpperCase(),
                QUANTIDADE: data.QUANTIDADE.toUpperCase()               

        }).then(() => setExibirModal(true))
        .catch((error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            })
        
}   
        
    return(
        
      
      <div className="w-90 p-3 mx-auto">
             <div className='Jumbotron'>
                <center> <h1> Cadastro Medicamento </h1> </center>
               <br />
            <div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-90 p-3 mx-auto">
            <div className="form-row">
                <div className="form-group col-md-6" >
                        Nome:
                        <Form.Control Style="text-transform:uppercase;"  
                        maxLength={45}
                        size="sm" type="text" name="NOME"  {...register('NOME')} required 
                        />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-6">
                        Dose:
                        
                        <Form.Control  size="sm" type="text" maxLength={120} name="DOSE" {...register('DOSE')} />
                </div>
            </div>            
            <div className="form-row">
                <div className="form-group col-md-2">
                        Quantidade:
                        <Form.Control  size="sm" type="text" maxLength={45} name="QUANTIDADE" {...register('QUANTIDADE')} />
                </div>
            </div>
            <br></br>
            <div className="form-row" >
                <div className="form-group col-md-10">
                    <Button 
                    className="btn btn-primary" 
                    type="submit" 
                    >
                        Gravar</Button>
                {/* </div>
                <div className="form-group col-md-2"> */}
                    <Button type="button" 
                    className="btn btn-secondary" 
                     onClick={() => {handleVoltar()}}
                    >
                Cancelar</Button>
                </div>    
            </div>   
          </div>   
        </Form> 
           </div> 
            </div> 
        <Modal show={exibirModal} onHide={handleFecharModal} backdrop="static">
                <Modal.Header>
                        <h2> Cadastro Medicamento </h2>
                </Modal.Header>
                <Modal.Body>
                        Medicamento Gravado!
                </Modal.Body>
                <Modal.Footer>
                        <Button variante="success" onClick={() => handleFecharModal()}> Ok </Button>
                </Modal.Footer>
                
        </Modal>
        <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 

        </div>
        
    )
};


