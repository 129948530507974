import React from 'react';


export default function DashAvisos(){

    
    return (
        <>
        {/* <center>
            <h5>Atualizaçõe What</h5>
            <td><h6>dia 20/10/23</h6></td>
            <tr>
            
                <td>Foram feitas Atualizações na página de agenda, agora você pode fazer encaixe de horários.</td>
            </tr>
                <tr><td>Agora quando se cadastra o paciente o Contato é o Celular, não mais o Telefone.</td>
                
            </tr>
        </center> */}
        <center>
            <h5>Atualizações</h5>
            <td><h6>dia 05/02/24</h6></td>
            <tr>
            
                <td>Foram feitas algumas Atualizações no visual do sistema.
                    Também foi adicionada a função WhatsApp, com um click no botão WhatsApp do agendamento do Paciente
                    você poderá enviar mensagem de Confirmação de consulta.
                </td>
            </tr>
            
        </center>
       </>
    )
}

