import React from 'react';

export default function RelReceituario() {

return(
    <div> 
        <h1>teste</h1> 
    </div>
)

}

