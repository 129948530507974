import React, { useState, useEffect} from 'react';
import api from '../conexao.js';

import draftToHtml from 'draftjs-to-html';
import draftToMarkdown from 'draftjs-to-markdown'
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState,  convertToRaw, ContentState } from 'draft-js';
import cabecalho from '../../img/novo.jpg'
import rodape from '../../img/rodape.jpg'
import logOut from '../logOut';

import {Modal, Form, Button} from 'react-bootstrap';
import PrintReports from './print.js';

export default function PedidosdeExame(props){

    const [html, setHTML] = useState('');
    const [contentBlock] = useState(htmlToDraft(html));
    const [contentState] = useState(ContentState.createFromBlockArray(contentBlock.contentBlocks));
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));   
    
    const [textoBanco, setTextoBanco] = useState();
    const [data, setData] = useState(new Date())
    const [dataTexto, setDataTexto] = useState()
    
    const [pedidodeexame, setPedidodeExame] = useState([]);
    const [codPedido, setCodPedido] = useState();
    const [texto, setTexto] = useState([]);
    const [codTexto, setCodTexto] = useState();
    const [disableData, setDisableData] = useState(false);
    const [mostraModal, setMostraModal] = useState(false);
    const [modalGravado, setModalGravado] = useState(false);
    const [modalMSG, setModalMSG] = useState(false);

    
    const [auxStatus, setAuxStatus] = useState(false)
    const [btnExcluir, setBtnExcluir] = useState(false)
    const [MSG, setMSG] = useState();
    const [dataBR, setDataBR] = useState();
    const [loading, setLoading] = useState(true)

    function onEditorStateChange(editorState) {
        setEditorState(editorState)
        setTextoBanco(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };
    
    function transformaData(d){
        var date = new Date(d)                      // converte a data ISO DateTimeStamp(como vem do banco) para DD/MM/YYYY
        date.setHours(0,0,0,0)                      // zera a hora 00:00:00
        return(date.getDate()+'/' + (date.getMonth()+1) + '/'+date.getFullYear())      
    }

    function transformaDataBanco(d){                     // converte data = new Date() para DD/MM/YYYY
        var date = new Date(d)                      // converte a data ISO DateTimeStamp(como vem do banco) para DD/MM/YYYY
        date.setHours(0,0,0,0)                      // zera a hora 00:00:00
        return(date.getFullYear()+'/' + (date.getMonth()+1) + '/'+date.getDate())   
    }
    
    useEffect(() => {
            
        async function buscaPedidodeExame() {
            await api.get('/pedidodeexame',{
            params: {           
                idPac: props.idPac
            }                
        }).then ( result => {
            // console.log(result.data)
            result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                COD1 = COD1.CODIGO;
                COD2 = COD2.CODIGO;
    
                if (COD1 > COD2) {
                    return -1;
                }
                if (COD1 < COD2) {
                    return 1;
                } return 0;
            }) 

                result.data.map( (r) => {
                    r.DATA = transformaData(r.DATA)    
                })
                
                setPedidodeExame(result.data);
                
                if (result.data[0]) {                                        
                    setCodPedido(result.data[0].CODIGO); 
                    // var oO = Buffer.from(result.data[0].TEXTO).toString();
                    // if (oO.includes('�')) {
                    //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].TEXTO).toString('latin1')))));    
                    // } else
                    // setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].TEXTO).toString())))); 
                    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data[0].TEXTOCONVERTIDO))));
                    setBtnExcluir(false)
                    setDataBR(result.data[0].DATA) 
                } else {
                    setBtnExcluir(true) 
                }
    
        }).catch(
            (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            })
    }
    
        async function buscaTexto(){
            await api.get('/textoportipo',{
                params: {           
                    tipoTexto: 10
                }                
            }).then ( result => {
                        
                        setTexto(result.data)
                        if (result.data[0]) {
                            setCodTexto(result.data[0].CODIGO) 
                            }  // para registrar o codTexto, pois select nao define codTexto 
                                                            //ao listar quando carrega tela.
            }).catch(
                (error) => {
                    setMSG(logOut(error))
                    setModalMSG(true)
                })
        }
        
        buscaPedidodeExame();
        buscaTexto();
        setLoading(false);
    
    },[props.idPac]);
    
     async function handleNovoPedido(){
               
        data.setHours(0,0,0,0) 
        
        setDisableData(true) //desativa o campo de data para não poder trocar
        
        await api.post('/pedidodeexame', {
                COD_PACIENTE: parseInt(props.idPac),
                DATA: transformaDataBanco(data),
                TEXTO: '<p></p>'
       }).catch(
        (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
        })
    
        await api.get('/pedidodeexame',{
                params: {           
                    idPac: props.idPac
                }                
        }).then ( result => {

            result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                COD1 = COD1.CODIGO;
                COD2 = COD2.CODIGO;
    
                if (COD1 > COD2) {
                    return -1;
                }
                if (COD1 < COD2) {
                    return 1;
                } return 0;
            }) 

            result.data.map( r => {
            r.DATA = transformaData(r.DATA)    
        })
        setPedidodeExame(result.data);
        setCodPedido(result.data[0].CODIGO);
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(''))));
        setDataTexto(transformaData(data))
        setBtnExcluir(false)
    }).catch(
        (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
        })
    setAuxStatus(true)
    }
    
    async function handleGravar(codRec){
            
            data.setHours(0,0,0,0) 
            setDataBR(transformaData(data))
            console.log(codRec)
            await api.put('/pedidodeexame/'+ codRec, {
                COD_PACIENTE: parseInt(props.idPac),
                DATA: transformaDataBanco(data),
                TEXTO: textoBanco
            }).then(
                // setModalGravado(true),
                setMSG('Gravado!'),
                setModalMSG(true),
                setBtnExcluir(false)
            ).catch(
                (error) => {
                    setMSG(logOut(error))
                    setModalMSG(true)
                })
    
    
        async function buscaPedidodeExame() {
            await api.get('/pedidodeexame',{
                params: {           
                    idPac: props.idPac
                }                
        }).then ( result => {

            result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                COD1 = COD1.CODIGO;
                COD2 = COD2.CODIGO;
    
                if (COD1 > COD2) {
                    return -1;
                }
                if (COD1 < COD2) {
                    return 1;
                } return 0;
            }) 

                        result.data.map( r => {
                        r.DATA = transformaData(r.DATA)    
        })
        setPedidodeExame(result.data);
        setCodPedido(result.data[0].CODIGO);
        // var oO = Buffer.from(result.data[0].TEXTO).toString();
        // if (oO.includes('�')) {
        //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].TEXTO).toString('latin1')))));    
        // } else
        // setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].TEXTO).toString())))); 
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data[0].TEXTOCONVERTIDO))));
    
        }).catch(
            (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            })
    }
        buscaPedidodeExame();
        setDisableData(false) //Ativa o campo data para alterar, depois de gravado
    }
    
    function handleCodPedido(e) {
         setCodPedido(e)
         pedidodeexame.map( receit => {
             if (parseInt(receit.CODIGO) === parseInt(e)) {
                // var oO = Buffer.from(receit.TEXTO).toString();
                // if (oO.includes('�')) {
                //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(receit.TEXTO).toString('latin1')))));    
                // } else
                // setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(receit.TEXTO).toString())))); 
         
                setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(receit.TEXTOCONVERTIDO))));  
                setDataTexto(receit.DATA)
                setDataBR(receit.DATA) 
             }
            })
    }
    
    function handleAdicionaTexto(){
        var textoAlterado;
        if ( typeof codTexto === 'undefined' ) {
            // alert('Selecione o Texto')
            setMSG('Selecione o Texto');
            setModalMSG(true)
          } else {
        texto.map( text => {            
            if (parseInt(text.CODIGO) === parseInt(codTexto)) { // criar um .map para percorrer toda a string


                // if (Buffer.from(text.TEXTO).toString().includes('�')) {   
                //     textoAlterado = Buffer.from(text.TEXTO).toString('latin1')
                // } else {
                    textoAlterado = text.TEXTOCONVERTIDO
                // }
                textoAlterado = textoAlterado.replace("%data", dataTexto)
                textoAlterado = textoAlterado.replace("%nome", props.paciente)
                onEditorStateChange(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(
                    draftToHtml(convertToRaw(editorState.getCurrentContent())) + textoAlterado))));   // VAI SER USADO PARA ADD TEXTO
            } 
        })
    }
    
    }
     
    function handleMostraModal(m){
        setMostraModal(m)
    }
    
    async function handleExcluirPedido(cod){
        await api.delete('/pedidodeexame/'+ parseInt(cod))
        .then(
            setMSG('Apagado!'),
            setModalMSG(true)
        )
        .catch(
            (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            })
        setMostraModal(false);
    
         
        await api.get('/pedidodeexame',{
            params: {           
                idPac: props.idPac
            }                
            }).then ( result => {
                if (result.data[0]) {
                result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                    COD1 = COD1.CODIGO;
                    COD2 = COD2.CODIGO;
        
                    if (COD1 > COD2) {
                        return -1;
                    }
                    if (COD1 < COD2) {
                        return 1;
                    } return 0;
                }) 

                            result.data.map( r => {
                            r.DATA = transformaData(r.DATA)    
            })
            setPedidodeExame(result.data);
            setDataBR(result.data[0].DATA)
            setCodPedido(result.data[0].CODIGO);      
            // var oO = Buffer.from(result.data[0].TEXTO).toString();
            // if (oO.includes('�')) {
            //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].TEXTO).toString('latin1')))));    
            // } else
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data[0].TEXTOCONVERTIDO))));            
            // setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].TEXTO).toString()))));

        } else {
            setBtnExcluir(true)
            setPedidodeExame(result.data)
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(''))));
        }
            }).catch(
                (error) => {
                    setMSG(logOut(error))
                    setModalMSG(true)
                })

              
    
    }

    function chamaPDF(){
        let auxTexto = convertToRaw(editorState.getCurrentContent()).blocks
        let texto =  auxTexto.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
        // console.log(props.crmMed)
    
        async function enderec() {
            await api.get('/medico/', {params: {
                codigo:parseInt(props.idMed)
            }} )
                .then (() => {PrintReports(texto, props.nomeMed, props.paciente, props.titulo, dataBR,props.crmMed,props.cabecalho,props.rodape)})
                .catch( (error) => {
                    setMSG(logOut(error))
                    setModalMSG(true)
                })              
        }
        enderec();
}  
    
    return(
        <div className="container-fluid">  
            <br />
            <div className=" form-row">
                <div className=" btn-group-vertical col-md-1">
                    <Button variant="primary" size="sm" 
                        onClick={() => handleNovoPedido()}
                        >Novo</Button>                  
                    <Button variant="danger" size="sm" 
                        onClick={() => handleMostraModal(true)}
                        disabled={btnExcluir}
                        >Excluir</Button>            
                    <Button variant="info" size="sm"
                        onClick={() => chamaPDF()}
                        >Imprimir</Button>
                        
                </div>
                <div className="col-md-1">
                </div>
                <div className="col-md-2">
                    Pedidos Emitidos:
                    <Form.Control  size="sm" as="select" 
                    value={codPedido} 
                    onChange={e => handleCodPedido(e.target.value)} 
                    disabled={disableData} >                               
                                        {pedidodeexame.map( pedid => (
                                            <option value={pedid.CODIGO}>{pedid.DATA}</option>
                                            ))}
                    </Form.Control> 
                </div>
                <div className="col-md-1">
                </div>
                <div className="col-md-3">
                    Texto:
                    <Form.Control  size="sm" as="select" 
                    value={codTexto} 
                    onChange={e => setCodTexto(e.target.value)} >                               
                                        {
                                        texto.map( text => (
                                            <option value={text.CODIGO}>{text.NOME}</option>
                                            ))}
                    </Form.Control> 
                    <Button variant="success" size="sm" onClick={m => handleAdicionaTexto()} >Adicionar Texto</Button>
                </div>
            </div>
            <div>
                <Editor 
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={onEditorStateChange}
                        placeholder="Digite o Texto"
                    />
            </div>
            <Button variant="primary" onClick={() => handleGravar(codPedido)}>Gravar</Button>
    
            <Modal show={mostraModal}>
                <Modal.Header> 
                    <h3>Excluir Pedido?</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="danger" onClick={() => handleExcluirPedido(codPedido)}>Excluir</Button>
                    <Button variant="info" onClick={() => handleMostraModal(false)}>Cancelar</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalGravado}>
                <Modal.Header> 
                    <h3>Gravado!</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalGravado(false)}>Ok</Button>
                </Modal.Footer>
            </Modal>      
            <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 
            <Modal show={loading}>
               <Modal.Body>
                Aguarde....
                </Modal.Body>
        </Modal>       
        </div>
    )

}