import React, {useState, useEffect} from 'react';
// import { Jumbotron } from 'react-bootstrap';
import logo from '../img/medsolutions.jpg';
import { Card, Table } from 'react-bootstrap';
import consultasHoje from './consultasHoje';
import DashAvisos from './dashboads/dashAvisos';
import DashConsultas from './dashboads/dashConsultas';



function DashBoard() {

    return ( 
        <div>
            {/* <Table> 
                <tr>
                   <th>Consultas</th>
                   <th>Pacientes</th>
                </tr>
                <tr>
                   <th>Atendimentos</th>
                   <th>Teste</th>
                </tr>
            </Table> */}



             <center>
                <br />
                <br />
                    <img src={logo} width="380" height="100" alt=""/>
                    <br />
                <br />
            </center> 
                    <div>
                        
                        
                        <div style={{display: "inline-block", padding: "10px", width:"400px",background: "#f8f9fa"}}><DashAvisos /></div>
                        {/* <div style={{display: "inline-block", padding: "10px", width:"400px",background: "#f8f9fa"}}><DashConsultas /></div> */}
                        {/* <div style={{display: "inline-block", padding: "10px", width:"400px",background: "#e3f2fd"}}></div>
                        <div style={{display: "inline-block", padding: "10px", width:"400px",background: "#e3f2fd"}}></div> */}
                    </div>


            {/* <consultasHoje></consultasHoje> */}
        </div>

    )
}

export default DashBoard;