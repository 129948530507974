import React, { useState, useEffect} from 'react';
import api from '../conexao.js';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import draftToMarkdown  from 'draftjs-to-markdown';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState,  convertToRaw, ContentState } from 'draft-js';

import cabecalho from '../../img/novo.jpg'
import rodape from '../../img/rodape.jpg'
import {Modal, Form, Button} from 'react-bootstrap';
import logOut from '../logOut';
import PrintReports from './print.js';

export default function Atestado(props){

    const [html, setHTML] = useState('');
    const [contentBlock] = useState(htmlToDraft(html));
    const [contentState] = useState(ContentState.createFromBlockArray(contentBlock.contentBlocks));
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));   
    
    const [textoBanco, setTextoBanco] = useState();
    const [data, setData] = useState(new Date())
    const [dataTexto, setDataTexto] = useState();

    
    const [atestado, setAtestado] = useState([]);
    const [codAtestado, setCodAtestado] = useState();
    const [texto, setTexto] = useState([]);
    const [codTexto, setCodTexto] = useState();
    const [disableData, setDisableData] = useState(false);
    const [mostraModal, setMostraModal] = useState(false);
    const [modalGravado, setModalGravado] = useState(false);
    const [modalMSG, setModalMSG] = useState(false);
    const [MSG, setMSG] = useState();
    
    const [auxStatus, setAuxStatus] = useState(false)
    const [btnExcluir, setBtnExcluir] = useState(false)  
    const [dataBR, setDataBR] = useState(); 
    const [loading, setLoading] = useState(true)

    var htmlRelAtestado = `
    <!DOCTYPE html><html><title>MedSolutions - Receituario</title><head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/7.0.0/normalize.min.css">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.4.1/paper.css">
    <style>@page { size: A4 } </style></head>
    <section class="sheet padding-10mm">
    <center><img src=${cabecalho} width="100%" heigth="40%" ></center>
    <center><h1>Atestado</h1></center><br /><body class="A4">
    <b><p><span style="color: rgb(0,0,0);font-size: 16px;font-family: Tahoma">Nome Paciente: </span></b>${props.paciente}</p>
    <b><p><span style="color: rgb(0,0,0);font-size: 16px;font-family: Tahoma">Data : </span></b>${transformaData(data)}</p>
    <body><p>${draftToHtml(convertToRaw(editorState.getCurrentContent()))}</p>
    <br /><center><p>${props.nomeMed}</p></center><center><p>${props.crmMed}</p></center>
    <br />
    <div style="position:absolute; top:920px; "><img src=${rodape} width="95%" heigth="40%" > </div>
    </section>
 
    </body>
    
    </html>`
    
    
    function onEditorStateChange(editorState) {
        setEditorState(editorState)
        setTextoBanco(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };
    
    function transformaData(d){
        var date = new Date(d)                      // converte a data ISO DateTimeStamp(como vem do banco) para DD/MM/YYYY
        date.setHours(0,0,0,0)                      // zera a hora 00:00:00
        return(date.getDate()+'/' + (date.getMonth()+1) + '/'+date.getFullYear())      
    }

    function transformaDataBanco(d){                // converte data = new Date() para DD/MM/YYYY
        var date = new Date(d)                      // converte a data ISO DateTimeStamp(como vem do banco) para DD/MM/YYYY
        date.setHours(0,0,0,0)                      // zera a hora 00:00:00
        return(date.getFullYear()+'/' + (date.getMonth()+1) + '/'+date.getDate())   
    }
    
    useEffect(() => {
            
        async function buscaAtestado() {
            await api.get('/atestado',{
            params: {           
                idPac: props.idPac
            }                
        }).then ( result => {

            result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                COD1 = COD1.CODIGO;
                COD2 = COD2.CODIGO;
    
                if (COD1 > COD2) {
                    return -1;
                }
                if (COD1 < COD2) {
                    return 1;
                } return 0;
            }) 

                result.data.map( (r) => {
                    r.DATA = transformaData(r.DATA)    
                })
                setAtestado(result.data);
                if (result.data[0]) {                                        
                    setCodAtestado(result.data[0].CODIGO);    
                    // var oO = Buffer.from(result.data[0].TEXTO).toString();
                    // if (oO.includes('�')) {
                    //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].TEXTO).toString('latin1')))));    
                    // } else {
                    //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].TEXTO,'latin1').toString()))));          
                    // }
                    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data[0].TEXTOCONVERTIDO))));
                    setBtnExcluir(false) 
                    setDataBR(result.data[0].DATA)
                } else {
                    setBtnExcluir(true) 
                    
                }
    
        }).catch(
            (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
            }
         )
    }
    
        async function buscaTexto(){
            await api.get('/textoportipo',{
                params: {           
                    tipoTexto: 11
                }                
            }).then ( result => {
                        setTexto(result.data)
                        if (result.data[0]) {
                            setCodTexto(result.data[0].CODIGO) 
                            }                               // para registrar o codTexto, pois select nao define codTexto 
                                                            //ao listar quando carrega tela.
            }).catch(
                (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
                }
             )
        }
        
        buscaAtestado();
        buscaTexto();
        setLoading(false);
    
    },[props.idPac]);
    
     async function handleNovoAtestado(){
               
        data.setHours(0,0,0,0) 
        
        setDisableData(true) //desativa o campo de data para não poder trocar
    
        await api.post('/atestado', {
                COD_PACIENTE: parseInt(props.idPac),
                DATA: transformaDataBanco(data),
                TEXTO: '<p></p>'
       })
       .catch(
        (error) => {
        setMSG(logOut(error))
        setModalMSG(true)
        }
     )
    
        await api.get('/atestado',{
                params: {           
                    idPac: props.idPac
                }                
        }).then ( result => {

            result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                COD1 = COD1.CODIGO;
                COD2 = COD2.CODIGO;
    
                if (COD1 > COD2) {
                    return -1;
                }
                if (COD1 < COD2) {
                    return 1;
                } return 0;
            }) 

                        result.data.map( r => {
                        r.DATA = transformaData(r.DATA)    
        })
        setAtestado(result.data);
        setCodAtestado(result.data[0].CODIGO);
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(''))));
        setDataTexto(transformaData(data))
        setBtnExcluir(false)

    }).catch(
        (error) => {
        setMSG(logOut(error))
        setModalMSG(true)
        }
     )
    setAuxStatus(true)
    }
    
    async function handleGravar(codRec){
    
            data.setHours(0,0,0,0) 
            await api.put('/atestado/'+ codRec, {
                COD_PACIENTE: parseInt(props.idPac),
                DATA: transformaDataBanco(data),
                TEXTO: textoBanco
            }).then(
                    setMSG('Gravado'),
                    setModalMSG(true),
                    // setModalGravado(true)

                )
            .catch(    (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            })
    
    
        async function buscaAtestado() {
            await api.get('/atestado',{
                params: {           
                    idPac: props.idPac
                }                
        }).then ( result => {

            result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                COD1 = COD1.CODIGO;
                COD2 = COD2.CODIGO;
    
                if (COD1 > COD2) {
                    return -1;
                }
                if (COD1 < COD2) {
                    return 1;
                } return 0;
            }) 

                        result.data.map( r => {
                        r.DATA = transformaData(r.DATA)    
        })
        setAtestado(result.data);
        setDataBR(result.data[0].DATA)
        setCodAtestado(result.data[0].CODIGO);
        // var oO = Buffer.from(result.data[0].TEXTO).toString();
        // if (oO.includes('�')) {
        //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].TEXTO).toString('latin1')))));    
        // } else
        // setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].TEXTO).toString()))));          
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data[0].TEXTOCONVERTIDO))));
    
        }).catch(
                (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
                }
            )
    }
        buscaAtestado();
        setDisableData(false) //Ativa o campo data para alterar, depois de gravado
    }
    
    function handleCodAtestado(e) {
         setCodAtestado(e)
         atestado.map( atest => {
             if (parseInt(atest.CODIGO) === parseInt(e)) {
                    setDataTexto(atest.DATA)
                    // var oO = Buffer.from(atest.TEXTO).toString();
                    // if (oO.includes('�')) {
                    //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(atest.TEXTO).toString('latin1')))));    
                    // } else
                    // setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(atest.TEXTO).toString()))));          
                    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(atest.TEXTOCONVERTIDO))));  
                    setDataBR(atest.DATA)
             }
            })
    }
    
    function handleAdicionaTexto(){
        var textoAlterado;
        if ( typeof codTexto === 'undefined' ) {
            // alert('Selecione o Texto')
            setMSG('Selecione o Texto');
            setModalMSG(true)
          } else {
        texto.map( text => {
            if (parseInt(text.CODIGO) === parseInt(codTexto)) {
                // if (Buffer.from(text.TEXTO).toString().includes('�')) {   
                //     textoAlterado = Buffer.from(text.TEXTO).toString('latin1')
                // } else {
                //     textoAlterado = Buffer.from(text.TEXTO).toString()           
                // }
                textoAlterado = text.TEXTOCONVERTIDO
                textoAlterado = textoAlterado.replace("%nome", props.paciente)
                textoAlterado = textoAlterado.replace("%data", dataTexto)
                onEditorStateChange(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(draftToHtml(convertToRaw(editorState.getCurrentContent())) +  textoAlterado ))));                         // VAI SER USADO PARA ADD TEXTO
            } 
        })
    }
    
    }
     
    function handleMostraModal(m){
         setMostraModal(m)
    }
    
    async function handleExcluirAtestado(cod){
        await api.delete('/atestado/'+ parseInt(cod))
        .then( 
            setMSG('Excluido!'),
            setModalMSG(true)
        
        ).catch(    (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
        });
        setMostraModal(false);
    
         
        await api.get('/atestado',{
            params: {           
                idPac: props.idPac
            }                
            }).then ( result => {
                if (result.data[0]) {
                    result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                    COD1 = COD1.CODIGO;
                    COD2 = COD2.CODIGO;
        
                    if (COD1 > COD2) {
                        return -1;
                    }
                    if (COD1 < COD2) {
                        return 1;
                    } return 0;
                }) 

                            result.data.map( r => {
                            r.DATA = transformaData(r.DATA)    
            })
            setAtestado(result.data);
            setDataBR(result.data[0].DATA)
            setCodAtestado(result.data[0].CODIGO);    
            // var oO = Buffer.from(result.data[0].TEXTO).toString();
            // if (oO.includes('�')) {
            //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].TEXTO).toString('latin1')))));    
            // } else
            // setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].TEXTO).toString()))));              
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data[0].TEXTOCONVERTIDO))));
                } else {
                    setBtnExcluir(true)
                    setAtestado(result.data)
                    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(''))));
                }    
        
        }).catch(
                (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
                }
             )

    }
    
    function handleImprimir(){
        var myWindow = window.open();
        myWindow.document.write(htmlRelAtestado)
    }    

    function chamaPDF(){
        let auxTexto = convertToRaw(editorState.getCurrentContent()).blocks
        let texto =  auxTexto.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

        async function enderec() {
            await api.get('/medico/', {params: {
                codigo:parseInt(props.idMed)
            }} )
                .then (() => {PrintReports(texto, props.nomeMed, props.paciente, props.titulo, dataBR,props.crmMed,props.cabecalho,props.rodape)})
                .catch( (error) => {
                    setMSG(logOut(error))
                    setModalMSG(true)
                })              
        }
        enderec();

      //  PrintReports(texto, props.nomeMed, props.paciente, props.titulo, dataBR,props.crmMed,props.cabecalho,props.rodape)
    }


    return(
        <div className="container-fluid">  
            <br />
            <div className=" form-row">
                <div className=" btn-group-vertical col-md-1">
                    <Button variant="primary" size="sm" 
                        onClick={() => handleNovoAtestado()}
                        >Novo</Button>                  
                    <Button variant="danger" size="sm" 
                        onClick={() => handleMostraModal(true)}
                        disabled={btnExcluir}
                        >Excluir</Button>            
                    <Button variant="info" size="sm"
                        onClick={() => chamaPDF()}
                        >Imprimir</Button>
                        
                </div>
                <div className="col-md-1">
                </div>
                <div className="col-md-2">
                    Atestado Emitidos:
                    <Form.Control  size="sm" as="select" 
                    value={codAtestado} 
                    onChange={e => handleCodAtestado(e.target.value)} 
                    disabled={disableData} >                               
                                        {atestado.map( atest => (
                                            <option value={atest.CODIGO}>{atest.DATA}</option>
                                            ))}
                    </Form.Control> 
                </div>
                <div className="col-md-1">
                </div>
                <div className="col-md-3">
                    Texto:
                    <Form.Control  size="sm" as="select" 
                    value={codTexto} 
                    onChange={e => setCodTexto(e.target.value)} >                               
                                        {texto.map( text => (
                                            <option value={text.CODIGO}>{text.NOME}</option>
                                            ))}
                    </Form.Control> 
                    <Button variant="success" size="sm" onClick={m => handleAdicionaTexto()} >Adicionar Texto</Button>
                </div>
            </div>
            <div>
                <Editor 
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={onEditorStateChange}
                        placeholder="Digite o Texto"
                    />
            </div>
            <Button variant="primary" onClick={() => handleGravar(codAtestado)}>Gravar</Button>
    
            <Modal show={mostraModal}>
                <Modal.Header> 
                    <h3>Excluir Atestado?</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="danger" onClick={() => handleExcluirAtestado(codAtestado)}>Excluir</Button>
                    <Button variant="info" onClick={() => handleMostraModal(false)}>Cancelar</Button>
                </Modal.Footer>
    
            </Modal>
            <Modal show={modalGravado}>
                <Modal.Header> 
                    <h3>Gravado!</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalGravado(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 
            <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal>  
            <Modal show={loading}>
               <Modal.Body>
                Aguarde....
                </Modal.Body>
        </Modal>  
        </div>
    )

}