import React, { useState }from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button, Form } from 'react-bootstrap';
import api from '../conexao.js';
import '../../App.css';
import { useNavigate} from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import InputMask from 'react-input-mask';
import logOut from '../logOut';
import AWS from 'aws-sdk';


export default function CadMedico(){
        const nave= useNavigate();


const {register, handleSubmit,errors} = useForm( );  //REGISTRANDO AS FUNÇÕES DO USEFORM
const [exibirModal, setExibirModal] = useState(false); // const para definir se a modal aparece ou nao
const [tel, setTEL] = useState();
const [cel, setCEL] = useState();
const [cpf, setCPF] = useState();
const [modalMSG, setModalMSG] = useState(false);
const [MSG, setMSG] = useState();

const [cabecalho, setCabecalho] = useState();
const [rodape, setRodape] = useState();

function handleFecharModal(){
        setExibirModal(false); // inicia faalse
        nave('/medico/medico'); // vai para pagina anterior
}

function handleVoltar(){
        nave('/medico/medico');
}

var telnum = String;
var celnum = String;
var cpfnum = String;
var fileName = String;

 const onSubmit = (data) => {
         
        if  (tel)  {
                telnum = tel;
                telnum = telnum.replace(/[^0-9]+/g, "");
        }
        if (cel) {
                celnum = cel;
                celnum = celnum.replace(/[^0-9]+/g, "");
        }
        if (cpf) {
                cpfnum = cpf;
                cpfnum = cpfnum.replace(/[^0-9]+/g, "");
        }


       async function grava() { 
                
                // if (cabecalho) { data.CABECALHO = 'CAB ' + data.NOME.substring(0,10) + '.JPEG'}
                // if (rodape)    { data.RODAPE    = 'ROD ' + data.NOME.substring(0,10) + '.JPEG'}
                
                if (cabecalho) { data.CABECALHO = 'cab ' + data.NOME.substring(0,10).toLowerCase() + '.jpg'}
                if (rodape)    { data.RODAPE    = 'rod ' + data.NOME.substring(0,10).toLowerCase() + '.jpg'}

                // console.log(cabecalho.name)
                // console.log(rodape.name)
                                               
               await api.post('/medico', {
                NOME: data.NOME.toUpperCase(),
                ENDERECO: data.ENDERECO.toUpperCase(),
                BAIRRO: data.BAIRRO.toUpperCase(),
                CIDADE: data.CIDADE.toUpperCase(),
                TEL: telnum,
                CEL: celnum,
                CPF: cpfnum,
                CRM: data.CRM.toUpperCase(),
                CABECALHO: data.CABECALHO,
                RODAPE: data.RODAPE

        }).then(() => setExibirModal(true))
        .catch( 
                (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            })   
        }

        const uploadCabecalho = async () => {
                const S3_BUCKET = "imgmedsolutions";
                const REGION = "sa-east-1";
                AWS.config.update({
                  accessKeyId: "AKIAY3BB4HQM7BZWG7OA",
                  secretAccessKey: "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",
                });
                const s3 = new AWS.S3({
                  params: { Bucket: S3_BUCKET },
                  region: REGION,
                });
            
                const params = {
                  Bucket: S3_BUCKET,
                  Key: data.CABECALHO,
                  Body: cabecalho,
                  Metadata: {
                        ContentType: 'image/jpeg',
                    }
                };
            
                var upload = s3
                  .putObject(params)
                  .on("httpUploadProgress", (evt) => {
                //     console.log(
                //       "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                //     );
                  })
                  .promise();
            
                await upload.then((err, data) => {
                  console.log(err);
                  alert("File uploaded successfully.");
                });
                await upload.catch((err, data) => {
   
                        alert("File uploaded Failed.");
                });
        };

        const uploadRodape = async () => {
                const S3_BUCKET = "imgmedsolutions";
                const REGION = "sa-east-1";
                AWS.config.update({
                        accessKeyId: "AKIAY3BB4HQM7BZWG7OA",
                        secretAccessKey: "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",
                });
                const s3 = new AWS.S3({
                        params: { Bucket: S3_BUCKET },
                        region: REGION,
                });

                const params = {
                        Bucket: S3_BUCKET,
                        // Key: rodape.name,
                        Key: data.RODAPE,
                        Body: rodape,                    
                        Metadata: {
                                ContentType: 'image/jpeg',
                            }
                };

                var upload = s3
                        .putObject(params)
                        .on("httpUploadProgress", (evt) => {
                        // console.log(
                        // "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                        // );
                        })
                        .promise();

                // await upload.then((err, data) => {
                //         console.log(err);
                //         alert("File uploaded successfully.");
                // });
                await upload.catch((err, data) => {
                        console.log(err);
                        alert("File uploaded Failed.");
                });
        };

   
        grava();
        if (cabecalho) {uploadCabecalho();}
        if (rodape) {uploadRodape();}
        



}       
function funcCabecalho(e) {
        const cabeca = e.target.files[0];
        setCabecalho(cabeca);
    }

function funcRodape(e) {
        const roda = e.target.files[0];
        setRodape(roda);
    }   
    return(
        
        <div className="w-90 p-3 mx-auto">
            <div className='Jumbotron'>
                <center> <h1> Cadastro Médico </h1> </center>
               <br />
            <div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-90 p-3 mx-auto">
             <div className="form-row">
                <div className="form-group col-md-6" >
                        Nome:
                        <Form.Control Style="text-transform:uppercase;"
                        maxLength={45} 
                        size="sm" type="text" name="NOME"  {...register('NOME',{minLength: 10}) } required
                        />
                        {/* {errors.NOME?.message && <p>{errors.NOME?.message}</p>} */}
                </div>
                <div className="form-group col-md-2">
                CRM:
                    <Form.Control Style="text-transform:uppercase;" 
                    type="text" 
                    size="sm" 
                    maxLength={15}
                    name="CRM" 
                    {...register('CRM')} 
                    required
                    />
                </div>
           
            </div>
            <div className="form-row">
            <div className="form-group col-md-6">
                        Endereço:
                        <Form.Control Style="text-transform:uppercase;" maxLength={45} size="sm" type="text" name="ENDERECO"  {...register('ENDERECO')}/>
                </div> 
                <div className="form-group col-md-6">
                        Bairro:
                        <Form.Control Style="text-transform:uppercase;" maxLength={45} size="sm" type="text" name="BAIRRO" {...register('BAIRRO')}/>
                </div>   
            </div>

            <div className="form-row">
            <div className="form-group col-md-6">
                        Cidade:
                        <Form.Control Style="text-transform:uppercase;" maxLength={45} size="sm" type="text" name="CIDADE" {...register('CIDADE')}/>
                </div> 

            </div>


            <div className="form-row">
            <div className="form-group col-md-3">
                        Tel.:
                        <br />
                        <InputMask
                                mask="(99)9999-9999"
                                size="24"
                                name="TEL"
                                {...register('TEL')}
                                value={tel}
                                onChange={(e) => setTEL(e.target.value)}
                                alwaysShowMask={false}
                        />
                </div> 
                <div className="form-group col-md-3">
                        Cel.:
                        <br />
                        <InputMask
                                mask="(99)99999-9999"
                                size="24"
                                name="CEL"
                                {...register('CEL')}
                                value={cel}
                                onChange={(e) => setCEL(e.target.value)}
                                alwaysShowMask={false}
                        />
                </div>
                <div className="form-group col-md-4">
                        CPF:
                        <br />
                        <InputMask
                                mask="999.999.999-99"
                                size="24"
                                name="CPF"
                                {...register('CPF')}
                                value={cpf}
                                onChange={(e) => setCPF(e.target.value)}
                                alwaysShowMask={false}
                        />
                </div>  
                <div className="form-group col-md-4">
                Cabeçalho
                    <Form.Control  
                    type="file" name="cabecalho" accept="image/jpeg"
                    onChange={e => funcCabecalho(e)} 
                    />
                </div>
                <br/>
                <div className="form-group col-md-4">
                Roda-pé
                    <Form.Control
                    type="file" name="rodape" accept="image/jpeg"
                    onChange={e => funcRodape(e)}
                    />
                </div>

            </div>
            <br></br>

            <div className="form-row" >
                <div className="form-group col-md-10">
                    <Button 
                    className="btn btn-primary" 
                    type="submit" 
                    >
                        Gravar</Button>
                {/* </div>
                <div className="form-group col-md-2"> */}
                    <Button type="button" 
                    className="btn btn-secondary" 
                     onClick={() => {handleVoltar()}}
                    >
                Cancelar</Button>
                </div>    
            </div> 
        </div>  
        </Form> 
           </div> 
            </div> 
        <Modal show={exibirModal} onHide={handleFecharModal} backdrop="static">
                <Modal.Header>
                        <h2> Cadastro Médico </h2>
                </Modal.Header>
                <Modal.Body>
                        Médico Gravado!
                </Modal.Body>
                <Modal.Footer>
                        <Button variante="success" onClick={() => handleFecharModal()}> Ok </Button>
                </Modal.Footer>
                
        </Modal>
        <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 

        </div>
        
    )
};


