import React, { useState, useEffect} from 'react';
import api from '../conexao.js';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
// import draftToMarkdown from 'draftjs-to-markdown';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState,  convertToRaw, ContentState } from 'draft-js';

import {Modal, Form, Button} from 'react-bootstrap';
import logOut from '../logOut';
import PrintReports from './print.js';
import ReceituarioControlado from './controlado.js';
import { Buffer } from 'buffer';

export default function Receituario(props){

const [html, setHTML] = useState('');
const [contentBlock] = useState(htmlToDraft(html));
const [contentState] = useState(ContentState.createFromBlockArray(contentBlock.contentBlocks));
const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));   

const [textoBanco, setTextoBanco] = useState();
const [data, setData] = useState(new Date())

const [receituario, setReceituario] = useState([]);
const [codReceituario, setCodReceituario] = useState();
const [medicamento, setMedicamento] = useState([]);
const [codMedicamento, setCodMedicamento] = useState();
const [disableData, setDisableData] = useState(false);
const [mostraModal, setMostraModal] = useState(false);
const [mostraModalRel, setMostraModalRel] = useState(false);
const [modalGravado, setModalGravado] = useState(false);
const [modalMSG, setModalMSG] = useState(false);
const [modalImprime, setModalImprime] = useState(false);


const [auxStatus, setAuxStatus] = useState(false)
const [btnExcluir, setBtnExcluir] = useState(false);
// const [txt, setTxt] = useState();
const [MSG, setMSG] = useState();
const [medico, setMedico] = useState();
const [dataBR, setDataBR] = useState();
const [loading, setLoading] = useState(true)


function onEditorStateChange(editorState) {
    setEditorState(editorState)
    setTextoBanco(draftToHtml(convertToRaw(editorState.getCurrentContent())));


};

function transformaData(d){                     // converte data = new Date() para DD/MM/YYYY
    var date = new Date(d)                      // converte a data ISO DateTimeStamp(como vem do banco) para DD/MM/YYYY
    date.setHours(0,0,0,0)                      // zera a hora 00:00:00
    return(date.getDate()+'/' + (date.getMonth()+1) + '/'+date.getFullYear())      
}

function transformaDataBanco(d){                     // converte data = new Date() para DD/MM/YYYY
    var date = new Date(d)                      // converte a data ISO DateTimeStamp(como vem do banco) para DD/MM/YYYY
    date.setHours(0,0,0,0)                      // zera a hora 00:00:00
    return(date.getFullYear()+'/' + (date.getMonth()+1) + '/'+date.getDate())   
}

useEffect(() => {
        
    async function buscaReceituario() {
        await api.get('/receituario',{
        params: {           
            idPac: props.idPac
        }                
    }).then ( result => {
        if (result.data[0]) {
        result.data.sort((COD1, COD2) => { // ORDENA LISTA DO COMBO POR CODIGO | ORDEM DECRESCENTE
            COD1 = COD1.CODIGO;
            COD2 = COD2.CODIGO;

            if (COD1 > COD2) {
                return -1;
            }
            if (COD1 < COD2) {
                return 1;
            } return 0;
        })

            result.data.map( r => {
                r.DATA = transformaData(r.DATA) 

            })
            
            setReceituario(result.data);
            if (result.data[0]) {
                setCodReceituario(result.data[0].CODIGO);   
                // var oO = Buffer.from(result.data[0].TEXTO).toString();
                // if (oO.includes('�')) {
                //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].TEXTO).toString('latin1')))));    
                // } else
                // setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].TEXTO).toString()))));          
                setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft( result.data[0].TEXTOCONVERTIDO))));
                setBtnExcluir(false)
                setDataBR(result.data[0].DATA)
            } else {
                setBtnExcluir(true) 
            }   
            }else { 
                setBtnExcluir(true)
             }
    }).catch(
            (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
            }
         )
    
    // .catch((error) => logOut(error))
}

    async function buscaMedicamento(){
        await api.get('/medicamento',{params:{
            nome: '%'
        }}).then (
            result => {
                setMedicamento(result.data)
                if (result.data[0]) {
                    setCodMedicamento(result.data[0].CODIGO) 
                    } 
            }, setLoading(false)
    ).catch(
        (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
            }
        )
}
    
    buscaReceituario();
    buscaMedicamento();


},[props.idPac]);

 async function handleNovaReceita(){
           // aux para saber se é um novo receituario se TRUE é novo RECIETA
    data.setHours(0,0,0,0) 
    

    
    setDisableData(true) //desativa o campo de data para não poder trocar

    await api.post('/receituario', {
            COD_PACIENTE: parseInt(props.idPac),
            DATA: transformaDataBanco(data),
            TEXTO: '<p></p>'
 }).catch(
                
    (error) => {
        setMSG(logOut(error))
        setModalMSG(true)
    }
    
    )
    await api.get('/receituario',{
            params: {           
                idPac: props.idPac
            }                
    }).then ( result => {

                    result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                        COD1 = COD1.CODIGO;
                        COD2 = COD2.CODIGO;

                        if (COD1 > COD2) {
                            return -1;
                        }
                        if (COD1 < COD2) {
                            return 1;
                        } return 0;
                    })

                    result.data.map( r => {
                    r.DATA = transformaData(r.DATA)  

            }
    )

    setReceituario(result.data);
    setCodReceituario(result.data[0].CODIGO);
    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(''))));
    setBtnExcluir(false)
}).catch(           
    (error) => {
        setMSG(logOut(error))
        setModalMSG(true)
    }
    )
setAuxStatus(true)
setBtnExcluir(false) // ATIVA O OBOTAO DE EXCLUIR
}

async function handleGravar(codRec){
        data.setHours(0,0,0,0) 
        setDataBR(transformaData(data))
        await api.put('/receituario/'+ codRec, {
            COD_PACIENTE: parseInt(props.idPac),
            DATA: transformaDataBanco(data),
            TEXTO: textoBanco
        }).then(  () =>  {setModalGravado(true)})
        .catch(
            (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            }
            )


    async function buscaReceituario() {
        await api.get('/receituario',{
        params: {           
            idPac: props.idPac
        }                
    }).then ( result => {

        result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
            COD1 = COD1.CODIGO;
            COD2 = COD2.CODIGO;

            if (COD1 > COD2) {
                return -1;
            }
            if (COD1 < COD2) {
                return 1;
            } return 0;
        })           

            result.data.map( r => {
                r.DATA = transformaData(r.DATA)    
            })
          
            setReceituario(result.data);
            setCodReceituario(result.data[0].CODIGO);     
            // var oO = Buffer.from(result.data[0].TEXTO).toString();
            //     if (oO.includes('�')) {
            //         setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].TEXTO).toString('latin1')))));    
            //     } else
            //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].TEXTO).toString())))); 
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data[0].TEXTOCONVERTIDO))));

    })
    .catch( 
        (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
        }
        )}
    buscaReceituario();
    setDisableData(false) //Ativa o campo data para alterar, depois de gravado
}

function handleCodReceituario(e) {
     setCodReceituario(e)
      
        
     receituario.map( receit => {
         if (parseInt(receit.CODIGO) === parseInt(e)) {
            // var oO = Buffer.from(receit.TEXTO).toString();
            // if (oO.includes('�')) {
            //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(receit.TEXTO).toString('latin1')))));    
            // } else
            // setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(receit.TEXTO).toString())))); 
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(receit.TEXTOCONVERTIDO))));   
        
             setDataBR(receit.DATA)
        
            
        }
         
        })
        
}

function handleAdicionaMedicamento(){
    if ( typeof codMedicamento === 'undefined') {
        // alert('Selecione o Medicamento')
        setMSG('Selecione o Medicamento');
        setModalMSG(true);
    } else {

    medicamento.map( medic => {
        if (parseInt(medic.CODIGO) === parseInt(codMedicamento)) {
            onEditorStateChange(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(
                draftToHtml(convertToRaw(editorState.getCurrentContent())) + '<p>' + medic.NOME + '</p><p>' + medic.DOSE + '</p>'))));                         // VAI SER USADO PARA ADD TEXTO
        } 
    })
}

}

function handleMostraModal(m){
    setMostraModal(m)
}

async function handleExcluirReceita(cod){
    await api.delete('/receituario/'+ parseInt(cod))
    .then( () => {setMSG('Excluido')
            setModalMSG(true)}

    )
    .catch( 
            (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
            }
        )
    
    setMostraModal(false);

     
    await api.get('/receituario',{
        params: {           
            idPac: props.idPac
        }                
        }).then (  
            result => {
            if (result.data[0]) {
                result.data.sort((COD1, COD2) => {// ORDENA LISTA DO COMBO POR CODIGO.
                    COD1 = COD1.CODIGO;
                    COD2 = COD2.CODIGO;
        
                    if (COD1 > COD2) {
                        return -1;
                    }
                    if (COD1 < COD2) {
                        return 1;
                    } return 0;
                })  
                
                result.data.map( r => {
                    r.DATA = transformaData(r.DATA)    
                })
                setDataBR(result.data[0].DATA)
                setReceituario(result.data);
                setCodReceituario(result.data[0].CODIGO);
                // var oO = Buffer.from(result.data[0].TEXTO).toString();
                // if (oO.includes('�')) {
                //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].TEXTO).toString('latin1')))));    
                // } else
                // setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(Buffer.from(result.data[0].TEXTO).toString())))); 
                setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result.data[0].TEXTOCONVERTIDO))));

            } else { //SE NAO TIVER RECEITUARIO APÓS APAGAR
            setReceituario(result.data)
            setBtnExcluir(true)
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(''))));
        }
    })    .catch( 
        (error) => {
            setMSG(logOut(error))
            setModalMSG(true)
        }
        )
}

function handleMostrarModalRel(m){
    setMostraModalRel(m);
}

async function chamaPDF(){
   
    let auxTexto = convertToRaw(editorState.getCurrentContent()).blocks
    let texto =  auxTexto.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
    
    async function enderec() {
        await api.get('/medico/', {params: {
            codigo:parseInt(props.idMed)
        }} )
            .then (() => {PrintReports(texto, props.nomeMed, props.paciente, props.titulo, dataBR,props.crmMed,props.cabecalho,props.rodape)})
            .catch( (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            })
            
    }

    enderec();
    setModalImprime(false);
    
 
} 

function chamaPDFContolado(){
 
    let auxTexto    = convertToRaw(editorState.getCurrentContent()).blocks
    let texto       = auxTexto.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
    
    async function enderec() {
        await api.get('/medico/', {params: {
            codigo:parseInt(props.idMed)
        }} ).then (
            async (result) =>  {
                setMedico(result.data) //joga a resposta em medico
                let ende = result.data.ENDERECO + ',' + result.data.BAIRRO + ' - ' + result.data.CIDADE +' - '+ result.data.TEL;
                await ReceituarioControlado(  ende,texto, props.nomeMed, props.paciente, props.titulo, 
                                        dataBR,props.crmMed,props.cabecalho,props.rodape)
        }).catch( (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            })
        
    }
    enderec();
    setModalImprime(false);
}

return(
    <div className="container-fluid">  
        <br />
        <div className=" form-row">
            <div className=" btn-group-vertical col-md-1">
                <Button variant="primary" size="sm"  onClick={() => handleNovaReceita()}>Novo</Button>                  
                <Button variant="danger" size="sm" disabled={btnExcluir} onClick={() => handleMostraModal(true)}>Excluir</Button>            
                <Button variant="info" size="sm" onClick={() => setModalImprime(true)}>Imprimir</Button>
                    
            </div>
            <div className="col-md-1">
            </div>
            <div className="col-md-2">
                Receituários Emitidos:
                <Form.Control  size="sm" as="select" 
                value={codReceituario} 
                onChange={e => handleCodReceituario(e.target.value)} 
                disabled={disableData} >                               
                                    {receituario.map( receit => (
                                        <option value={receit.CODIGO}>{receit.DATA}</option>
                                        ))}
                </Form.Control> 
            </div>
            <div className="col-md-1">
            </div>
            <div className="col-md-3">
                Medicamento:
                <Form.Control  size="sm" as="select" 
                value={codMedicamento} 
                onChange={e => setCodMedicamento(e.target.value)} >                               
                                    {medicamento.map( medic => (
                                        <option value={medic.CODIGO}>{medic.NOME}</option>
                                        ))}
                </Form.Control> 
                <Button variant="success" size="sm" onClick={m => handleAdicionaMedicamento()} >Adicionar a Receita</Button>
            </div>
        </div>
        <div>
            <Editor 
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={onEditorStateChange}
                    placeholder="Digite o Texto"
                />
        </div>
        <Button variant="primary" onClick={() => handleGravar(codReceituario)}>Gravar</Button>

        <Modal show={mostraModal}>
            <Modal.Header> 
                <h3>Excluir Receita?</h3>
            </Modal.Header> 
            <Modal.Footer>
                <Button variant="danger" onClick={() => handleExcluirReceita(codReceituario)}>Excluir</Button>
                <Button variant="info" onClick={() => handleMostraModal(false)}>Cancelar</Button>
         </Modal.Footer>
        </Modal>

        <Modal show={modalGravado}>
                <Modal.Header> 
                    <h3>Gravado!</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalGravado(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 

        <Modal show={mostraModalRel}>
            <Modal.Header> 
                <h3>Que tipo de Receita?</h3>
            </Modal.Header> 

        </Modal>
        <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 
            <Modal show={modalImprime}>
                <Modal.Header> 
                    <h3>Qual tipo de Receituário?</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => chamaPDF()}>Comum</Button>
                    <Button variant="info" onClick={() => chamaPDFContolado()}>Controlado</Button>
                    <Button variant="danger" onClick={() => setModalImprime(false)}>Cancelar</Button>
                </Modal.Footer>
            </Modal> 
            <Modal show={loading}>
               <Modal.Body>
                Aguarde....
                </Modal.Body>
        </Modal>  
    </div>
)

}

