import React, { useState, useEffect }from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button, Form, Jumbotron } from 'react-bootstrap';
import api from '../conexao.js';
import { useNavigate, useParams } from 'react-router-dom';

import '../../App.css';
import logOut from '../logOut';


export default function EditarMedicamento(){

    const nave = useNavigate();
    const {id} = useParams()
// EditarMedicamento.propTypes = {
//     id: PropTypes.number.isRequired
// }

const {register, handleSubmit, setValue} = useForm( );  //REGISTRANDO AS FUNÇÕES DO USEFORM

const [exibirModal, setExibirModal] = useState(false); // const para definir se a modal aparece ou nao
const [medicNome, setMedicNome] = useState();
const [MSG, setMSG] = useState();
const [modalMSG, setModalMSG] = useState(false);


useEffect( () => {
    api.get('/medicamento/', {  params: {           
        codigo: id}}).then(result => {
           setValue("NOME", result.data.NOME);
           setValue("DOSE", result.data.DOSE);
           setValue("QUANTIDADE", result.data.QUANTIDADE);

    }).catch((error) => {
        setMSG(logOut(error))
                setModalMSG(true)
              })  
}, [id]);



function handleFecharModal(){
        setExibirModal(false); // inicia faalse
        nave('/medicamento/medicamento'); // vai para pagina anterior
   //     window.location.reload(); //da um load na pagina
}

function handleVoltar(){
    nave('/medicamento/medicamento');
    //    window.location.reload(); 
}



 const onSubmit = async (data) => {

        const response = 
        await api.put('/medicamento/'+ id, {
                NOME:  data.NOME.toUpperCase(),
                DOSE:  data.DOSE.toUpperCase(),
                QUANTIDADE:  data.QUANTIDADE.toUpperCase()

        }).then(() => {
        setMedicNome(data.NOME);
        setExibirModal(true);})
        .catch((error) => {
            setMSG(logOut(error))
                    setModalMSG(true)
                  })  
    }

        return (

            
            <div className="w-90 p-3 mx-auto">
            <div className='Jumbotron'>
                <center> <h1> Editar Medicamento </h1> </center>
            <br />
            <div>
        <Form onSubmit={handleSubmit(onSubmit)}  >
          <div className="w-90 p-3 mx-auto">
            <div className="form-row">
                <div className="form-group col-md-6">
                        Nome:
                        <Form.Control Style="text-transform:uppercase;" 
                        size="sm" 
                        maxLength={45}
                        type="text"  
                        name="NOME"  
                        {...register('NOME')}
                        />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-6">
                        Dose:
                        <Form.Control  size="sm" type="text" maxLength={120} name="DOSE" {...register('DOSE')} />
                </div>
            </div>
            <div className="form-row">
            <div className="form-group col-md-2">
                        Quantidade:
                        <Form.Control Style="text-transform:uppercase;" maxLength={45} size="sm" type="text" name="QUANTIDADE"  {...register('QUANTIDADE')}/>
                </div> 
            </div> 
            <br></br>
             <div className="form-row" >
                <div className="form-group col-md-10">
                    <Button 
                    className="btn btn-primary" 
                    type="submit" 
                    >
                        Gravar</Button>
                {/* </div>
                <div className="form-group col-md-2"> */}
                    <Button type="button" 
                    className="btn btn-secondary" 
                    onClick={() => {handleVoltar()}}
                    >
                Cancelar</Button>
                </div>    
            </div>   
          </div>    
            </Form> 
        </div> 
            </div> 
        <Modal show={exibirModal} onHide={handleFecharModal} backdrop="static">
                <Modal.Header>
                        <h2> Alteração feita com Sucesso! </h2>
                </Modal.Header>
                <Modal.Body>
                  Paciente: <strong>{medicNome}</strong>, Alterado!                  
                </Modal.Body>
                <Modal.Footer>
                        <Button variante="success" onClick={() => handleFecharModal()}> Ok </Button>
                </Modal.Footer>
                
        </Modal>
        <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 
        </div>
 

                )

}



