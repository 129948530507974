import React from 'react';
import ReactDOM from 'react-dom';
 import { BrowserRouter } from "react-router-dom";
import './index.css';
import Login from './login';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
// import Menu from './componentes/menu';

ReactDOM.render(
  // <React.StrictMode>
    // <App />,

    <Login />,

    
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
